@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.breadcrumb {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    padding: 16px 32px;
    background-color: $white;

    @include mobile {
        min-height: 30px;
        padding: 16px;
    }
}

.breadcrumb__item {
    display: inline-flex;
    padding: 0 16px;
    border-left: 1px solid $blue-pale;
    font-size: 12px;
    line-height: 15px;
    color: $blue-pale;
    overflow: hidden;

    @include mobile {
        padding: 0 8px;
    }

    span {
        display: inline-block;
    }

    &-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &.is-active {
        color: var(--secondary-color);
    }

    &:first-child {
        border: none;
    }
}

.breadcrumb__item-index {
    margin-right: 10px;
}

.breadcrumb__item-title {}