@import "../../../globals/styles/_fonts.scss";@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.checkbox {
    position: relative;
    margin-right: 10px;
}

.checkbox__input {
    appearance: none;
    width: 20px;
    height: 20px;
    margin: 0;
    border: 2px solid var(--primary-color);
    ;

    &:checked {
        background-color: var(--primary-color);
        ;
    }
}

.checkbox__input:checked+.checkbox__icon {
    opacity: 1;
}

.checkbox__icon {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 16px;
    height: 16px;
    color: $white;
    pointer-events: none;
    opacity: 0;
}