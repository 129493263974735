@import "../../../globals/styles/_fonts.scss";@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.animator__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animator__title {
  padding: 32px;
  font-size: 35px;
}

.animator__horizontalLine {
  height: 1px;
  background: black;
  margin: 32px;
  width: 100%;
  max-width: 317px;
}

.animator__section {
  font-size: 18px;
  font-weight: bold;
}

.animator__campaign-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  width: 100%;
  gap: 16px;
}

.animator__campaign-button {
  border-radius: 10px;
}

.animator__future-campaign {
  cursor: unset;

  &:hover {
    color: var(--primary-color);
    background-color: #ffffff;
  }
}

.animator__noCampaign {
  width: 100%;
  padding: 24px 0px 24px 0px;
  display: flex;
  justify-content: center;
  align-self: stretch;
  background: var(--Bg-Color, #f4f8fe);
  border-radius: 10px;

  color: $grey-dark;
  font-family: 'CenturyGothic', sans-serif;
  font-size: 18px;
}
