/*
** Media Queries
*/

$mq-tablet : 1100px !default;
$mq-tablet-sm : 770px !default;
$mq-mobile : 500px !default;
$mq-mobile-sm : 350px !default;

@mixin tablet {
    @media (max-width : $mq-tablet) {
        @content;
    }
} 

@mixin mobile-height {
    @media screen and (max-height: 780px){
        @content;
    }
}

@mixin mobile-height-iphone {
    @media screen and (max-height: calc(780px - 172px)){
        @content;
    }
}

@mixin tablet-sm {
    @media (max-width : $mq-tablet-sm) {
        @content;
    }
}

@mixin mobile {
    @media (max-width : $mq-mobile) {
        @content;
    }
}

@mixin mobile-sm {
    @media (max-width : $mq-mobile-sm) {
        @content;
    }
}