@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.popin__container {
    position: fixed; left: 0; top: 0; z-index: 100;
    width: 100%; height: 100%;
    pointer-events: none;
    // display: none;
    &.is-active {
        pointer-events: all;
    }
}
.popin__underlay {
    position: absolute; left: 0; top: 0; z-index: 1;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.4);
    opacity: 0; transition: 0.25s;
    .is-active & {
        opacity: 1; transition: 0.5s;
    }
}
.popin {
    position: absolute; 
    left: 50%; 
    bottom: 0; 
    z-index: 2;
    //display: flex; flex-direction: column; justify-content: center; align-items: center;
    text-align: center;
    width: 100%; padding: 15px 20px;
    border-radius: 10px 10px 0 0; background-color: $white;
    transform: translate(-50%, 100%); 
    transition: 0.25s;
    .is-active & {
        transform: translate(-50%, 0); transition: 0.5s;
    }
    min-height: 100px;

    //@include mobile{
    //    display: block;
    //
    //}
}
.popin__close {
    //position: absolute; right: 20px; top: 20px;
    float: right;
    padding: 10px; color: $black;
}