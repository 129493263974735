@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  background-color: $white;
  padding: 32px;

  @include tablet {
    min-height: 0;
    padding: 20px;
  }

  @include mobile {
    padding: 16px;
  }
}

.header__disconnect {
  position: absolute;
  left: 30px;
  top: 50%;
  padding: 5px;
  color: #baceea;
  cursor: pointer;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
    vertical-align: top;
  }
}

.header__logo {
  max-width: 60%;
  max-height: 150px;
  margin: 0;
  font-size: 35px;
  letter-spacing: 0.1em;
  font-weight: 400;

  color: var(--secondary-color);

  b {
    color: var(--primary-color);
    font-weight: 700;
  }

  @include mobile {
    font-size: 25px;
  }
}

.header__language {
  position: absolute;
  right: 30px;
  top: 50%;
  z-index: 1;
  padding: 5px;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  @include mobile {
    font-size: 20px;
  }

  cursor: pointer;
  transform: translateY(-50%);
}

.header__language-current {
  color: var(--primary-color);
}

.header__language-dropdown {
  position: absolute;
  left: 50%;
  top: calc(100% + 10px);
  padding: 3px;
  background-color: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  color: $grey-dark;
  opacity: 0;
  transform: translateY(-5px) translateX(-50%);
  transition: 0.2s;

  .header__language.is-active & {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

.header__language-item {
  padding: 10px 12px;

  &.is-active {
    color: var(--secondary-color);
    pointer-events: none;
  }
}
