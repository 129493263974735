/*
** Fonts
*/

// Century Gothic
@font-face {
  font-family: 'CenturyGothic';
  src: url('../fonts/CenturyGothic.woff2') format('woff2'), url('../fonts/CenturyGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('../fonts/CenturyGothic-Bold.woff2') format('woff2'), url('../fonts/CenturyGothic-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Din
@font-face {
  font-family: 'Din';
  src: url('../fonts/DINPRO-REGULAR.woff2') format('woff2'), url('../fonts/DINPRO-REGULAR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Din';
  src: url('../fonts/DINPRO-REGULAR-ITALIC.woff2') format('woff2'), url('../fonts/DINPRO-REGULAR-ITALIC.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Din';
  src: url('../fonts/DINPRO-BOLD.woff2') format('woff2'), url('../fonts/DINPRO-BOLD.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Din';
  src: url('../fonts/DINPRO-BOLD-ITALIC.woff2') format('woff2'), url('../fonts/DINPRO-BOLD-ITALIC.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Din';
  src: url('../fonts/DINPRO-BLACK.woff2') format('woff2'), url('../fonts/DINPRO-BLACK.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Din';
  src: url('../fonts/DINPRO-BLACK-ITALIC.woff2') format('woff2'), url('../fonts/DINPRO-BLACK-ITALIC.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
