@import "../../../globals/styles/_fonts.scss";@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.processIA {
    display: flex;
    width: 100%;
    overflow: hidden;
    &.is-transition {pointer-events: none;}
    &.is-prev {
        flex-direction: row-reverse;
    }
}

.processIA__step {
    flex-shrink: 0;
    width: 100%;
    .processIA.is-transition & {
        transition: 0.3s;
        transform: translateX(-100%);
    }
    .processIA.is-transition.is-prev & {
        transition: 0.3s;
        transform: translateX(100%);
    }
}