/*
** Colors
*/
$blue-pale: rgba(140, 174, 220, 0.6);
$red: #eb5757;
$green: #27ae60;

$grey-pale: #f4f8fe;
$grey: #bdbdbd;
$grey-medium-dark: #828282;
$grey-dark: #4f4f4f;

$white: #ffffff;
$black: #000000;

:root {
  --primary-color: #d9059e;
  --primary-color-pale: #f4b4e2;
  --primary-color-dark: #bf048a;
  --secondary-color: #000000; //RT-349
  --disabled-color: #cdcfd1;
  --grey-pink: #b58eaa;
}
