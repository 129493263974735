@import "../../../../globals/styles/_fonts.scss";@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.recapStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.recapStep__loading {
  flex-grow: 1;
  width: 100%;
}

.recapStep__heading {
  padding-top: 40px;
  padding-bottom: 20px;

  @include mobile {
    padding: 5px 0px 5px 5px;
  }
}

.recapStep__title {
  margin-bottom: 30px;
  text-align: center;
  line-height: 43px;
}

.recapStep__subtitle {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
}

.recapStep__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  background: linear-gradient(55deg, var(--primary-color) 23.39%, rgba(217, 5, 158, 0) 128.49%);
  //background-color: var(--primary-color);
  overflow: hidden;
}

.recapStep__body-title {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 30px;
  color: $white;
  text-align: center;

  @include mobile {
    margin-bottom: 15px;
  }
}

.recapStep__body-cards {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 0px;
  }
}

.recapStep__body-description {
  color: $white;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-weight: 700;
}

.recapStep__body-cta {
  margin-top: 15px;

  svg {
    margin-right: 10px;
  }
}

.recapStep__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 0;

  @include mobile {
    min-height: 0;
    padding: 20px 0;
  }
}

.recapStep__footerText {
  font-family: CenturyGothic;
  font-size: 14px;
  cursor: pointer;
}

// Diagnosis Card
.recapDiagnosisCard {
  //position: absolute; left: 50%; top: 0; z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 90%;
  height: auto;
  max-height: 100%;
  margin: 0 10px;
  border-radius: 30px;
  background-color: $white;
  box-shadow: 0px 5px 15px rgba(108, 3, 79, 0.15);
  overflow: hidden;
  //transform: translateX(-50%); transform-origin: center;

  .recapStep__body-cards--even & {
    &:nth-child(2n - 1) {
      z-index: 2;
      top: 50px;
      left: 33%;
      transform: translateX(-50%) rotate(-6.7deg);
    }

    &:nth-child(2n) {
      z-index: 1;
      top: 55px;
      left: 67%;
      transform: translateX(-50%) rotate(10.2deg);
    }
  }

  .recapStep__body-cards--odd & {
    &:nth-child(3n - 1) {
      z-index: 1;
      top: 50px;
      left: 28%;
      transform: translateX(-50%) rotate(-6.7deg);
    }

    &:nth-child(3n) {
      z-index: 1;
      top: 40px;
      left: 72%;
      transform: translateX(-50%) rotate(10.2deg);
    }
  }
}

.recapDiagnosisCard__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  font-size: 24px;
  color: var(--secondary-color);
  text-align: center;
}

.recapDiagnosisCard__visual {
  position: relative;
  width: 100%;
  padding-top: 84%;

  :global(.spinner) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.recapDiagnosisCard__visual-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recapDiagnosisCard__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.recapDiagnosisCard__rating {
  display: flex;
  align-items: center;

  &-percentage {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;

    &-number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .recapDiagnosisCard__rating--red & {
        color: $red;
      }

      .recapDiagnosisCard__rating--pink & {
        color: var(--primary-color);
      }

      .recapDiagnosisCard__rating--green & {
        color: $green;
      }
    }

    &-circle {
      width: 100%;
      height: 100%;
      fill: transparent;
    }

    &-circle-bg {
      stroke-width: 4px;
      stroke-linecap: round;
      stroke: #f4f5f7;
    }

    &-circle-fg {
      stroke-width: 4px;
      stroke-linecap: round;

      .recapDiagnosisCard__rating--red & {
        stroke: $red;
      }

      .recapDiagnosisCard__rating--pink & {
        stroke: var(--primary-color);
      }

      .recapDiagnosisCard__rating--green & {
        stroke: $green;
      }
    }
  }

  &-label {
    margin-left: 20px;
    font-weight: bold;
    color: var(--primary-color);
    font-size: 24px;

    .recapDiagnosisCard__rating--red & {
      color: $red;
    }

    .recapDiagnosisCard__rating--pink & {
      color: var(--primary-color);
    }

    .recapDiagnosisCard__rating--green & {
      color: $green;
    }
  }
}

@include tablet-sm {
  .recapStep__title {
    margin-bottom: 0;
  }

  .recapStep__subtitle {
    font-size: 18px;
    line-height: 20px;
  }

  .recapStep__body-cards {
    //overflow-x: auto;
    //justify-content: flex-start;
    margin-bottom: 0;
    padding-bottom: 20px;

    .recapDiagnosisCard {
      width: 90%;
      height: auto;
    }
  }

  .recapStep__body-title {
    font-size: 22px;
    max-width: 90%;
  }

  .recapStep__body-description {
    margin: 20px 0;
    max-width: 80%;
  }

  .recapStep__body-cta {
    margin-top: 15px;
    font-size: 16px;
    max-width: 80%;
    font-weight: normal;
    min-width: unset;
  }
}

@include mobile {
  .recapStep__body-description {
    margin: 5px 0;
    max-width: 80%;
  }

  .recapStep__subtitle {
    margin: 10px 0;
  }
}
