@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.personnalisationStep {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.personnalisationStep__title {
    //min-height: 90px;
    margin: 32px;
    text-align: center;

    @include mobile {
        margin: 16px;
        font-size: 1.3em;
        min-height: 0;
    }
}

.personnalisationStep__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;

    @include tablet-sm {
        width: 100%;
    }

    @include mobile {
        //flex-grow: unset;
        // display: block;
    }

}

.personnalisationStep__description {
    flex: 0.9;
    margin: 32px;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    font-size: 16px;
    line-height: 20px;

    b,
    strong {
        color: var(--secondary-color);
        font-style: italic;
    }

    @include mobile {
        display: block;
        text-align: justify;
        margin: 0 0 calc(10vh) 0;
        //line-height: 25px;
        padding: 0 !important;
    }

    @include tablet-sm {
        padding: 0 10px;
        flex: 1;
    }

}

.personnalisationStep__consent {
    padding-bottom: 48px;

    input {
        vertical-align: top;
    }

    &-label {
        display: inline-flex;
        justify-content: center;
        align-items: flex-start;
    }

    &-link {
        margin-left: 5px;
        color: var(--primary-color);
        ;
        font-weight: 700;
    }
}

.personnalisationStep__footer {
    margin-top: auto;
}

.personnalisationStep__footerCTA {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 32px;
    @include mobile {
        padding-bottom: 16px;
    }

    .personnalisationStep__footerCTA__spinner {
        width: auto;
    }
}

.personnalisationStep__footerCTA-abandon {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-height: 100px;
    //padding: 25px 0px 0px 0px;
    font-size: 14px;

    @include mobile {
        //padding-top: 20px;
    }
}

.center {
    text-align: center;
}

.submitButton {
    display: flex !important;
    margin: auto;
    margin-top: 8px;
}