@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.touPopin__wrapper {
    height: 83vh;
    max-height: calc(100vh - 110px);
    padding: 0 30px;
    text-align: center;

    b,
    strong {
        color: var(--primary-color);
    }
}

.touPopin__title {
    margin-bottom: 30px;
    color: var(--secondary-color);
    font-size: 35px;
    line-height: 42px;
}

.touPopin__subtitle {
    margin-bottom: 60px;
    color: var(--secondary-color);
    font-size: 24px;
    line-height: 30px;
}

.touPopin__description {
    text-align: left;
    font-size: 15px;
    line-height: 19px;

    b,
    strong {
        font-size: 18px
    }
}