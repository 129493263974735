@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.pictureStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.pictureStep__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  // border: 1px solid red;
  padding-top: 32px;

  @include mobile {
    padding-top: 16px;
    //flex-grow: unset;
  }
}

.pictureStep__video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 100%;
  border-radius: 30px;
  background-color: $black;
  overflow: hidden;

  &.is-picture {
    background-color: $white;
  }

  input {
    position: absolute;
    opacity: 0;
  }
}

.pictureStep__popin--error {
  display: flex;
  flex-direction: column;

  :first-child {
    font-weight: 700;
    color: var(--primary-color);
  }

  p {
    margin-top: 15px;

    @include mobile-height-iphone {
      margin-top: 5px;
    }

    word-break: break-word;
    width: 100%;
    padding-bottom: 15px;
    text-align: left;
  }

  span {
    text-decoration: underline;
    margin-top: 20px;
    display: block;
    color: var(--primary-color);
  }

  //@include mobile {
  //    margin-top: 20px;
  //}
}

.pictureStep__legal-notice-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    margin-top: 10px;
    width: 50%;
    min-height: 42px;
    font-size: 14px;
  }

  &--link {
    color: var(--primary-color-dark);
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
      color: var(--primary-color-dark);
    }
  }
}

.pictureStep__legal-notice-consent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pictureStep__legal-notice-consent-item {
  margin-top: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
}

.pictureStep__video-flash {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0;
  pointer-events: none;
  transition: 0.08s;

  &.is-active {
    opacity: 1;
  }
}

.pictureStep__video-countdown {
  display: none;
  position: absolute;
  right: 8%;
  top: 8%;
  z-index: 10;
  color: $white;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  &.is-active {
    display: block;
  }

  .pictureStep__video.is-picture & {
    display: none;
  }

  &-text {
    width: 100% !important;
    height: 100% !important;
  }
}

.pictureStep__video-scan {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #cce7ff 100%);
  transition: 0.8s;
  opacity: 0;
  animation: SCAN 18s infinite;

  &.is-active {
    opacity: 1;
  }
}

@keyframes SCAN {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(100vw + 40px));
  }
}

.pictureStep__video-mask {
  position: absolute;
  left: 50%;
  bottom: -5px;
  z-index: 10;
  height: 90%;
  width: 100%;
  color: $white;
  opacity: 0.8;
  transform: translateX(-50%);
  transition: 0.1s;

  svg {
    width: 100%;
    height: 100%;
  }

  .pictureStep__video.is-picture & {
    opacity: 0;
  }
}

.pictureStep__video-input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.2s 0.2s;
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);

  .pictureStep__video.is-picture & {
    opacity: 0;
  }
}

.pictureStep__video-image {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  .pictureStep__video.is-picture & {
    z-index: 3;
  }
}

.pictureStep__video-canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  .pictureStep__video.is-picture & {
    display: none;
  }
}

.pictureStep__body {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  text-align: center;
  overflow: hidden;
  padding-top: 20px;

  @include mobile {
    //flex-grow: unset;
  }

  &.is-transition {
    pointer-events: none;
  }

  &.is-prev {
    flex-direction: row-reverse;
  }
}

.pictureStep__step {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    text-align: justify;
    min-height: calc(35vh);
  }

  .pictureStep__body.is-transition & {
    transition: 0.5s;
    transform: translateX(-100%);
  }

  .pictureStep__body.is-transition.is-prev & {
    transition: 0.5s;
    transform: translateX(100%);
  }

  &--centered {
    justify-content: center;
  }
}

.pictureStep__step-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.pictureStep__step-title {
  //margin-bottom: 15px;
  font-size: 35px;
  line-height: 42px;
  text-align: center;

  @include mobile {
    font-size: 24px;
    line-height: normal;
    //margin-bottom: 10px;
  }

  b,
  strong {
    color: var(--primary-color);
  }

  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: var(--primary-color);
  }
}

.pictureStep__step-hiddenContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-height: 0;
  transition: 0.5s 0.5s;
  overflow: hidden;

  &.is-active {
    max-height: 300px;
  }

  @include mobile {
    align-items: unset;
  }
}

.pictureStep__step-description {
  flex: 1;
  margin-bottom: 20px;
  width: 560px;
  max-width: 100%;
  min-height: 66px;
  font-size: 18px;
  line-height: normal;

  // border: 1px solid red;
  @include mobile {
    //font-size: 14px;
    //line-height: 17px;

    //flex: none;
  }

  @include mobile-height-iphone {
    margin-bottom: 0;
  }
}

.pictureStep__step-button {
  width: 538px;
  margin-bottom: 0;
}

.pictureStep__step-footer {
  //padding-top: 10px;
  text-align: center;
  //margin-bottom: 20px;
  font-size: 14px;
  line-height: 17px;

  span {
    cursor: pointer;
  }

  @include mobile {
    //margin-bottom: 10px;
  }

  @include mobile-height-iphone {
    //margin-bottom: 0;
  }
}
.pictureStep__step-footerCTA {
  margin-bottom: 62px;
  @include mobile {
    margin-bottom: 44px;
  }
}

.pictureStep__step-footerCTA-alt {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 16px;
  margin-bottom: 32px;
  @include mobile {
    margin-bottom: 16px;
  }
}

.pictureStep__step-footerCTAButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding-bottom: 10px;

  //button {
  //    text-transform: uppercase;
  //    width: 100%;
  //}

  @include mobile {
    align-items: initial;
  }
}

// Steps
.pictureStep__analysis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  overflow: hidden;
  width: 100%;

  @include mobile {
    text-align: center;
  }
}

.pictureStep__analysis__progress {
  width: 80px;
  font-size: 24px;
  margin-bottom: 24px;
  color: var(--primary-color);
  flex: 1;

  @include mobile-height-iphone {
    margin-bottom: 5px;
  }
}

.pictureStep__analysis__check {
  width: 32px;
  height: 32px;
  margin: 0 auto 24px auto;
  color: var(--primary-color);
}

.pictureStep__analysis__container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  transition-property: all;
  transition-timing-function: ease-in-out;
  width: 100%;
}

.pictureStep__analysis__container--item {
  width: 100%;
  flex: 0 0 100%;
  color: var(--primary-color);
  font-size: 24px;
}
