@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.popinError__container {
    display: flex;
    flex-direction: column;
}

.popinError__title {
    font-weight: 700;
    color: var(--primary-color);
    ;
}

.popinError__description {
    margin-top: 15px;

    @include mobile-height-iphone {
        margin-top: 5px;
    }

    word-break: break-word;
    width: 100%;
    padding-bottom: 15px;
    text-align: left;
}

.popinError__backHome {
    text-decoration: underline;
    margin-top: 20px;
    display: block;
    color: var(--primary-color);
    ;
}