@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.inner {
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.inner--very-full {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
}

.inner--full {
    padding-left: 0;
    padding-right: 0;
}

.inner--lg {
    padding-left: 30px;
    padding-right: 30px;
}

.inner--md {
    padding-left: 60px;
    padding-right: 60px;

    @include mobile {
        padding: 0;
    }
}

.inner--sm {
    padding-left: 120px;
    padding-right: 120px;
}