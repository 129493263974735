@import "../../../../globals/styles/_fonts.scss";@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.codeValidationStep {
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;

  min-height: 100vh;
}

.codeValidationStep__heading {
  padding: 32px;

  @include mobile {
    padding: 16px;
  }
}

.codeValidationStep__title {
  text-align: center;
  line-height: normal;
  font-family: CenturyGothic;
  font-size: 35px;
  color: #3c3636;
  margin-bottom: 16px;
  @include mobile {
    font-size: 24px;
  }
}

.codeValidationStep__subtitle {
  text-align: center;
  font-family: CenturyGothic;
  font-size: 24px;
  line-height: normal;
  color: var(--primary-color);

  @include mobile {
    font-size: 18px;
  }
}

.codeValidationStep__input {
  width: 240px;
  margin-top: 250px;
}

.codeValidationStep__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 32px;
}

.codeValidationStep__button {
  width: fit-content;
}
