@import "../../../globals/styles/_fonts.scss";@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.input {
  margin-bottom: 32px;
  width: 100%;

  @include mobile-height-iphone {
    margin-bottom: 0px;
  }
}

.input__input {
  appearance: none;
  width: 100%;
  min-height: 68px;
  padding: 24px 16px;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background-color: $white;
  outline: none;
  font-size: 20px;

  @include mobile {
    margin-top: 20px;
  }

  @include mobile-height-iphone {
    min-height: 55px;
  }

  &::placeholder {
    color: $grey;
    font-size: 20px;
  }
}
