@import "../../../../globals/styles/_fonts.scss";@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.recommendationStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;

  @include mobile {
    display: flex;
    height: 100vh;
  }
}

.recommendationStep__loading {
  flex-grow: 1;
  width: 100%;
}

.recommendationStep__heading {
  padding: 32px;

  @include mobile {
    padding: 16px;
  }
}

.recommendationStep__title {
  text-align: center;
  line-height: normal;
  font-size: 35px;
  margin-bottom: 16px;
  @include mobile {
    font-size: 24px;
  }
}

.recommendationStep__subtitle {
  text-align: center;
  font-size: 24px;
  line-height: normal;
  color: var(--primary-color);

  @include mobile {
    font-size: 18px;
  }
}

.recommendationStep__body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 32px 16px 0;
  @include mobile {
    padding-top: 16px;
  }
}

.recommendationStep__footer {
  width: 100%;
  padding: 16px 0 32px;
  //background-color: $grey-pale;

  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: auto;

  @include mobile {
    padding: 16px 0 16px;
  }
}

//.recommendationStep__footer-inner {
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    align-items: center;
//}

//.recommendationStep__footer-selected {
//    margin-bottom: 20px;
//    text-align: center;
//
//    @include mobile {
//        margin-bottom: 5px;
//    }
//}

.recommendationStep__more {
  text-align: center;
  margin: 30px 0;
}

@include tablet-sm {
  .recommendationStep__more {
    button {
      border: none;
      text-decoration: underline;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.recommendationStep__submit {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-right: 20px;
    margin-left: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
  }
}

.recommendationStep__abandon {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-height: 100px;
  font-size: 14px;
}

.recommendationStep__noRecommendation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  text-align: center;
}

.recommendationStep__noRecommendation__titleContainer {
  margin-top: 32px;
}

.recommendationStep__noRecommendation__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 24px 48px;
  text-align: center;
  background-color: #f4f5f7;
  border-radius: 48px;
  max-width: 652px;
}

.recommendationStep__noRecommendation__message {
  font-size: 24px;
  line-height: 30px;
  color: black;
  @include mobile {
    font-size: 17px;
  }
}

.recommendationStep__noRecommendation__backHome {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 14px;
}
