@import "../globals/styles/_fonts.scss";@import "../globals/styles/_media-queries.scss";@import "../globals/styles/_variables.scss";.error__wrapper {
    height: 100%;
    padding: 30px;
    text-align: center;

    b,
    strong {
        color: var(--primary-color);
    }
}

.error__title {
    margin-bottom: 30px;
    color: var(--secondary-color);
    font-size: 35px;
    line-height: 42px;
}

.error__subtitle {
    margin-bottom: 60px;
    color: var(--secondary-color);
    font-size: 24px;
    line-height: 30px;
}

.error__description {
    text-align: center;
    font-size: 15px;
    line-height: 19px;
}

.error_backHome {
    margin-top: 30px;
    cursor: pointer;
}