@import "../../../../globals/styles/_fonts.scss";@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.purchaseValidationStep {
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;

  min-height: 100vh;
}

.purchaseValidationStep__loading {
  flex-grow: 1;
  width: 100%;
}

.purchaseValidationStep__heading {
  padding: 32px;

  @include mobile {
    padding: 16px;
  }
}

.purchaseValidationStep__back-link {
  width: 100%;
  padding-left: 30px;
  padding-top: 15px;
}

.purchaseValidationStep__back-link--item {
  display: inline-block;
  padding: 2px;
  color: var(--primary-color);
  font-size: 14px;
}

.purchaseValidationStep__back-icon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.purchaseValidationStep__title {
  text-align: center;
  line-height: normal;
  font-size: 35px;
  margin-bottom: 16px;
  @include mobile {
    font-size: 24px;
  }
}

.purchaseValidationStep__subtitle {
  text-align: center;
  font-size: 24px;
  line-height: normal;
  color: var(--primary-color);

  @include mobile {
    font-size: 18px;
  }
}

.purchaseValidationStep__body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.purchaseValidationStep__routine__container {
  margin-bottom: 24px;
  text-align: center;
}

.purchaseValidationStep__routine__title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;

  span {
    display: inline-block;
    margin-right: 30px;
    font-size: 35px;
  }
}

.purchaseValidationStep__routine__card {
  display: flex;
  justify-content: center;
}

.purchaseValidationStep__routine__card + .purchaseValidationStep__routine__card {
  margin-top: 12px;
}

.purchaseValidationStep__footer {
  width: 100%;
  padding: 20px 0 15px;
  background-color: $grey-pale;

  @include mobile {
    padding: 10px 0 0px;
  }
}

.purchaseValidationStep__footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.purchaseValidationStep__footer-selected {
  margin-bottom: 20px;
  text-align: center;
}

@include mobile {
  .purchaseValidationStep__routine__container {
    .purchaseValidationStep__routine__title {
      max-width: 80%;
      font-size: 16px;
      line-height: unset;
      background: #f4f8fe;
      display: inline-block;
      padding: 10px;
      border-radius: 30px;

      span {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
}
