@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 68px;
  max-width: 100%;
  padding: 16px 48px;
  background-color: var(--primary-color);
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  color: $white;
  font-size: 20px;
  /*font-weight: 700;*/
  text-align: center;
  line-height: 24px;
  transition: 0.2s;
  margin: auto;
  width: 500px;
  text-transform: uppercase;

  &:hover {
    background-color: var(--primary-color-dark);
    color: $white;
  }

  @include mobile {
    text-transform: uppercase;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    margin-top: 20px;
    min-height: 40px;
    width: 100%;
    border-radius: 5px;
  }

  @include mobile-height-iphone {
    margin-top: 10px;
  }

  &--fullwidth {
    width: 100%;
  }

  &--outline {
    background-color: $white;
    border-radius: 34px;
    color: var(--primary-color);
    font-weight: 700;
  }

  &--medium {
    min-height: 50px;
    min-width: 375px;

    @include mobile {
      min-height: 35px;
    }
  }

  &--small {
    min-height: 35px;
    min-width: 190px;
    font-size: 14px;
  }

  &--white {
    background-color: $white;
    border-color: $white;
    color: var(--primary-color);
    &:hover {
      background-color: var(--primary-color);
      color: $white;
    }
  }

  &.is-disabled,
  &:disabled {
    background-color: var(--disabled-color);
    border-color: var(--disabled-color);
    color: $white;
    pointer-events: none;
  }
}
