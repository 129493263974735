@import "../../../globals/styles/_fonts.scss";@import "../../../globals/styles/_media-queries.scss";@import "../../../globals/styles/_variables.scss";.homeStep {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include mobile {
        overflow-y: hidden;
    }

    // border: 1px solid red;
}

// body{
//     @include mobile{
//         overflow-y: visible;
//     }
// }
.homeStep__visual {
    position: relative;
    width: 100%;
    margin-bottom: 20px; //padding-top: 81.5%; max-height: 670px; 

    @include mobile {
        margin-bottom: 0;
    }
}

.homeStep__visual-image {
    //position: absolute; left: 0; top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10% 50%;
}

.homeStep__content {

    display: flex;
    flex-direction: column;


    align-items: center;

    @include mobile {
        text-align: justify;
        padding: 0px 10px 0px 10px;

    }
}

.homeStep__title {
    //font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include mobile {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }

    @include mobile-height-iphone {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }
}

.homeStep__subtitle {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--primary-color);
    ;

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }

    @include mobile-height-iphone {
        font-size: 16px;
        line-height: 20px;
    }
}

.homeStep__description {
    margin: 32px 0 20px;
    text-align: center;

    @include mobile {
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
        text-align: left;
    }
}

.homeStep__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;

    a {
        margin-top: 20px;
        color: black;
        text-decoration: none;
        font-size: 14px;

        @include mobile-height-iphone {
            margin-top: 10px;
        }
    }

    @include mobile {
        padding-bottom: 0;
        // border: 1px solid blue;
        width: 100%;
    }
}

.homeStep__button {
    text-transform: uppercase;
    font-weight: 700;
}

.popin__close__link {
    text-decoration: underline;
    margin-top: 20px;
    display: block;
    color: var(--primary-color);
    ;

}

.popin__error__desc {
    margin-top: 15px;
    word-break: break-word;
    width: 100%;
    font-weight: 700;
    color: var(--primary-color);
    ;
}

.popin__code__instruction {
    margin-bottom: 20px;

    @include mobile {
        margin-bottom: 0;
        text-align: justify;
    }
}

.popin__error__code {
    color: red;
    text-align: center;
    margin-bottom: 20px;

    @include mobile {
        margin-bottom: 0;
    }
}

.popin__error__geo {
    display: flex;
    flex-direction: column;
    // border: 1px solid red;

    & p:first-child {
        margin-top: 15px;

        @include mobile-height-iphone {
            margin-top: 5px;
        }

        word-break: break-word;
        width: 100%;
        font-weight: 700;
        color: var(--primary-color);
        ;
        padding-bottom: 15px;
        text-align: center;
    }

    & ul {
        text-align: left;
    }

    &--hook {
        font-weight: 700;
    }

    & em {
        font-style: normal;
        color: #3E3E7C;
    }
}