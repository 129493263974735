@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.popinPdf__container {
  height: 90vh;
  padding: 20px;
}
.popinPdf__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popinPdf__embed {
  width: 90%;
  overflow: scroll;
  border: 1px grey dashed;
}
.popinPdf__footer {
  margin-top: 20px;
}