@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.recommendationRoutine {
    padding: 0;

    @include mobile {
        //padding: 0;
    }
    &:not(.recommendationRoutine--active) {
        padding-bottom: 16px;
    }
}

.recommendationRoutine__heading {
    //padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .recommendationRoutine--active & {
        cursor: auto;
    }
}

.recommendationRoutine__title {
    font-size: 18px; //line-height: 32px;

    span {
        display: inline-block;
        margin-right: 30px;
        font-size: 18px;
    }

    flex-grow: 1;
    //max-width: 70%;/
}

.recommendationRoutine__status {
    max-width: 230px;

    p {
        line-height: 32px;
    }

    &-blue {
        color: $blue-pale;
    }

    &-pink {
        color: var(--primary-color);
        ;
    }

    img {
        max-width: 40%;
        display: block;
        margin-left: auto;
    }

    &-none {
        max-width: 230px;
        opacity: 0.3;
        pointer-events: none;
        padding: 8px 16px;
        font-weight: normal;

        &:hover,
        &:active,
        &:focus {
            background-color: $white;
            color: var(--primary-color);
            ;
        }
    }

    &-product {
        position: relative;
        display: block;
        max-width: 230px;
        padding: 8px 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;

        &:hover,
        &:active,
        &:focus {
            background-color: $white;
            color: var(--primary-color);
            ;
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-delete {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            display: block;
            width: 34px;
            height: 34px;
            padding: 12px;
            pointer-events: all;

            svg {
                width: 100%;
                height: 100%;
                vertical-align: top;
            }
        }
    }
}

.recommendationRoutine__body {
    width: 100%;
    transition: 0.2s;
    overflow: auto;
    height: 1px;

    .recommendationRoutine--active & {
        max-height: fit-content;
        height: auto;
    }

    @include tablet {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }
    }

    @include mobile {
        overflow-y: unset;
    }
}

.recommendationRoutine__slider {
    display: flex;
    width: 100%;
    padding: 20px 30px 25px;
    max-width: 500px;

    :global(.slick-list) {
        overflow: visible;
    }

    @include mobile {
        padding: 20px 0 25px;
    }
}

@include tablet-sm {
    .recommendationRoutine__heading {
        align-items: center;

        .recommendationRoutine__title {
            font-size: 16px;
            line-height: unset;
            flex-grow: 1;
            max-width: 65%;

            span {
                margin-right: 10px;
            }
        }
    }

    .recommendationRoutine__status {
        button {
            min-width: unset;
        }

        display: contents;

        img {
            max-width: 30%;
        }
    }
}