@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.title {
    @include tablet-sm {
        font-size: 24px;
    }

    font-size: 35px;
    line-height: 43px;
    color: var(--secondary-color);
    font-weight: 400;

    b,
    strong {
        color: var(--primary-color);
        ;
        font-weight: 700;
    }

    @include mobile-height-iphone {
        line-height: unset;
    }

    &--pink {
        color: var(--primary-color);
        ;

        b,
        strong {
            color: var(--secondary-color);
            font-weight: 700;
        }
    }
}