@import "_fonts.scss";@import "_media-queries.scss";@import "_variables.scss";* {
  box-sizing: border-box;
}

body {
  margin:
    env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Din', 'Segoe UI', 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: $grey-dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inner {
  max-width: 820px;
  padding: 0 30px;
  margin: 0 auto;
}

.inner-md {
  max-width: 820px;
  padding: 0 60px;
  margin: 0 auto;
}

.inner-lg {
  max-width: 820px;
  padding: 0 120px;
  margin: 0 auto;
}

.title {
  font-size: 35px;
  color: var(--secondary-color);
  font-weight: 400;
  line-height: 43px;

  b,
  strong {
    color: var(--primary-color);
    ;
    font-weight: 700;
  }

  &--pink {
    color: var(--primary-color);
    ;

    b,
    strong {
      color: var(--secondary-color);
      font-weight: 700;
    }
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

svg {
  width: 20px;
  height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}