@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &--small {
        width: 20px;
        height: 20px;
    }

    &--medium {
        width: 40px;
        height: 40px;
    }

    &--large {
        width: 60px;
        height: 60px;
    }
}

.spinner__icon {

    svg {
        width: 100%;
        height: 100%;
        color: $grey-pale;
        fill: var(--secondary-color);
        animation: SPIN 1.2s infinite linear;
    }
}

@keyframes SPIN {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}