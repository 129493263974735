@import "../../../../globals/styles/_fonts.scss";@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.diagnosisStep {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.diagnosisStep__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.diagnosisStep__loading {
  flex-grow: 1;
  width: 100%;
}

.diagnosisStep__heading {
  padding: 32px 16px;

  @include mobile {
    padding: 16px;
  }
}

.diagnosisStep__title {
  font-size: 35px;
  text-align: center;
  line-height: normal;

  @include mobile {
    margin: 0;
    font-size: 24px;
  }
}

.diagnosisStep__submit {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-right: 20px;
    margin-left: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
  }
}

.diagnosisStep__body {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 32px;
  overflow: auto;

  @include tablet {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      /* for Chrome, Safari, and Opera */
    }
  }

  @include mobile {
    padding-bottom: 16px;
    overflow-y: unset;
  }
}

.diagnosisStep__footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.diagnosisStep__abandon {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-height: 100px;
  padding-bottom: 32px;
  font-size: 14px;

  @include mobile {
    padding-bottom: 16px;
  }
}

.diagnosisStep__cards {
  display: flex;
  width: 100%;
  padding: 32px 0;
  margin: 0 auto;
  max-width: 575px;
  gap: 16px;

  @include mobile {
    padding: 16px 0;
  }

  :global(.slick-list) {
    overflow: visible;
  }

  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    height: auto;

    & > div {
      height: 100%;
    }
  }
}

.diagnosisCardContainer {
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 30px;
  }
}

.diagnosisCard {
  width: 475px;
  max-width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: 0px 5px 15px rgba(108, 3, 79, 0.15);
  display: flex;
  flex-direction: column;

  @include tablet-sm {
    width: calc(100vw * 0.8);
  }

  @include mobile {
    width: calc(100vw * 0.7);
    padding-bottom: 10px;
  }
}

.diagnosisCard__heading {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;

  @include mobile {
    padding: 16px;
  }

  &-age {
    display: flex;
    justify-content: center;
    padding: 16px 32px;
    .diagnosisCard__title {
      height: 70px;
    }
  }
}

.diagnosisCard__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  line-height: 35px;

  @include mobile {
    font-size: 16px;
  }
}

.diagnosisCard__rating {
  display: flex;
  align-items: center;
  justify-content: center;

  &-percentage {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;

    &-number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .diagnosisCard__rating--red & {
        color: $red;
      }

      .diagnosisCard__rating--pink & {
        color: var(--primary-color);
      }

      .diagnosisCard__rating--green & {
        color: $green;
      }
    }

    &-circle {
      width: 100%;
      height: 100%;
      fill: transparent;
    }

    &-circle-bg {
      stroke-width: 4px;
      stroke-linecap: round;
      stroke: #f4f5f7;
    }

    &-circle-fg {
      stroke-width: 4px;
      stroke-linecap: round;

      .diagnosisCard__rating--red & {
        stroke: $red;
      }

      .diagnosisCard__rating--pink & {
        stroke: var(--primary-color);
      }

      .diagnosisCard__rating--green & {
        stroke: $green;
      }
    }
  }

  &-label {
    margin-left: 12px;
    font-weight: bold;
    color: var(--primary-color);
    font-size: 24px;
    width: min-content;

    .diagnosisCard__rating--red & {
      color: $red;
    }

    .diagnosisCard__rating--pink & {
      color: var(--primary-color);
    }

    .diagnosisCard__rating--green & {
      color: $green;
    }
    @include mobile {
      font-size: 16px;
    }
  }
}

.diagnosisCard__visual {
  position: relative;
  width: 100%;
  padding-top: 89%;

  @include mobile {
    padding-top: 130%;
  }

  :global(.spinner) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.diagnosisCard__visual-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include mobile {
    object-position: top;
  }

  &-age {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(55deg, var(--primary-color) 23.39%, rgba(217, 5, 158, 0) 128.49%);
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 128px;

    @include mobile {
      font-size: 64px;
    }
  }
}

.diagnosisCard__description {
  padding: 16px 32px;
  font-size: 18px;
  line-height: 22px;

  overflow-y: auto;

  @include mobile {
    padding: 16px;
    height: calc(70vh - 430px);
  }
}
