@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.pictureStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
}

.pictureStep__legal-notice-consent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pictureStep__legal-notice-consent-item {
  margin-top: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
}

.pictureStep__legal-notice-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    margin-top: 10px;
    width: 50%;
    min-height: 42px;
    font-size: 14px;
  }

  &--link {
    color: var(--primary-color-dark);
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
      color: var(--primary-color-dark);
    }
  }
}

.LiqaContainer {
  width: 100%;
  height: 100%;
}
