@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.abandonPopin__title {
    margin-bottom: 28px;
    color: var(--secondary-color);
    font-size: 24px;
    line-height: 30px;
    text-align: center;
}

.abandonPopin__footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    button {
        margin-bottom: 15px;
    }

    @include tablet-sm {
        flex-direction: column;
    }
}

.abandonPopin__cta {
    margin: 0 10px;
    border-radius: 10px;

    @include mobile {
        margin: 0;
    }
}