@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.multiStepForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @include mobile {
    //min-height: unset;
  }
}

.multiStepForm__inner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  // border: 1px solid red;
  @include mobile {
    padding-top: 10px;
    //flex-grow: unset;
  }
}

.multiStepForm__back {
  min-height: 26px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;

  @include mobile {
    margin-bottom: 0px;
  }
}

.multiStepForm__back-link {
  display: inline-block;
  padding: 2px;
  color: var(--primary-color);
  font-size: 14px;
}

.multiStepForm__back-icon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.multiStepForm__count {
  //margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  color: $grey-medium-dark;

  @include mobile {
    //margin-bottom: 0;
  }
}

.multiStepForm__body {
  display: flex;
  flex-grow: 1; //justify-content: flex-end;
  width: 100%;
  overflow: hidden;

  &.is-transition {
    pointer-events: none;
  }

  &.is-prev {
    flex-direction: row-reverse;
  }
}

.multiStepForm__step {
  flex-shrink: 0;

  .multiStepForm__body.is-transition & {
    transition: 0.3s;
    transform: translateX(-100%);
  }

  .multiStepForm__body.is-transition.is-prev & {
    transition: 0.3s;
    transform: translateX(100%);
  }
}
