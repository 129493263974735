@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.purchaseCardContainer {
    padding: 0 10px;
    //:not(:only-child) :last-child {
    //    padding-right: 40px;
    //}
}

.purchaseCard {
    display: flex;
    flex-direction: column;
    width: 420px;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(108, 3, 79, 0.15);
    overflow: hidden;
    opacity: 0;
    transition: 0.2s;

    &.purchaseCard--visible {
        opacity: 1;
    }
}

.purchaseCard__body {
    flex-grow: 1;
    display: flex;
    width: 100%;
    padding: 15px;
    background-color: $white;
}

.purchaseCard__visual {
    flex-shrink: 0;
    width: 110px;
    height: 110px;
}

.purchaseCard__visual-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.purchaseCard__content {
    padding-left: 15px;
    color: $grey-dark;
}

.purchaseCard__title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
}

.purchaseCard__brand {
    font-size: 14px;
    margin-top: 10px;
}

.purchaseCard__description {
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
}

.purchaseCard__footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 42px;
    background-color: $grey-pale;
    color: var(--primary-color);
    ;
    font-size: 14px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;

    .purchaseCard--active & {
        background-color: var(--primary-color);
        ;
        color: $white;
        font-weight: 700;
    }

    svg {
        position: absolute;
        right: 20px;
        top: 50%;
        width: 12px;
        height: 12px;
        color: $white;
        transform: translateY(-50%);
    }
}

.purchaseCard__footer-default {

    .purchaseCard--active & {
        display: none;
    }
}

.purchaseCard__footer-active {
    display: none;

    .purchaseCard--active & {
        display: block;
    }
}

@include tablet-sm {
    .purchaseCard {
        width: calc(100vw * 0.8);
    }

    .purchaseCard__body {
        flex-direction: column;
        align-items: center;
    }
}