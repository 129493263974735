@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.activityPopin__overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: 0.25s;

    &.is-active {
        opacity: 1;
        pointer-events: all;
        transition: 0.5s;
    }
}

.activityPopin {
    position: fixed;
    left: 50%;
    top: 40%;
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    max-width: 90%;
    padding: 50px;
    border-radius: 10px;
    background-color: $white;
    transform: translate(-50%, -50%) scale(0.5);
    transition: 0.5s;

    .is-active & {
        transform: translate(-50%, -50%) scale(1);
    }
}

.activityPopin__title {
    text-align: center;
    margin-bottom: 28px;
    color: var(--secondary-color);
    font-size: 24px;
    line-height: 30px;
}

.activityPopin__clock {
    width: 160px;
    height: 160px;
    margin-bottom: 40px;
}