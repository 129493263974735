@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.login {
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    text-align: center;
    
    @include mobile{
        padding: 0;
    }

}
.login__title {
    font-weight: bold;
}
.login__subtitle {
    margin-top: 30px;
    @include tablet-sm{
        width: 100%;
    }
    @include mobile{
        margin-top: 10px;
        text-align: center;
    }
}
.login__error {
    margin-top: 40px; min-height: 22px;
    color: $red;
}
.login__form {
    @include tablet-sm{
        margin: auto;
        width: 100%;
    }
}
.login__submit {
    margin-top: 30px;
}