@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.popinDiagnosis__title {
    margin: 49px auto 30px;
    color: var(--secondary-color);
    font-size: 24px;
    line-height: 30px;
    text-align: center;
}

.popinDiagnosis__footer {
    text-align: center;
}

.popinDiagnosis__subtitle {
    font-size: 16px;
}

.popinDiagnosis__backHome {
    font-size: 14px;
    margin: 30px auto 49px;
}