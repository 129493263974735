@import "../../globals/styles/_fonts.scss";@import "../../globals/styles/_media-queries.scss";@import "../../globals/styles/_variables.scss";.animatorNewCampaign__body {
  display: flex;
  padding: 118px 32px 32px 32px;
  flex-direction: column;
  align-items: center;
}

.animatorNewCampaign__title {
  font-size: 35px;
  line-height: normal;
  padding-bottom: 29px;
}

.animatorNewCampaign__subtitle {
  font-size: 18px;
  line-height: normal;
  padding-bottom: 32px;
}

.animatorNewCampaign__form {
  padding: 0 10px 32px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  & > * {
    margin-bottom: 0;
  }
}

.animatorNewCampaign__brandsSubtitle {
  align-self: flex-start;
  color: #3c3636;
  font-family: 'CenturyGothic';
  font-size: 20px;
  font-weight: 400;
}

.animatorNewCampaign__brandContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.animatorNewCampaign__brandButton {
  margin-bottom: 16px;
  padding: 24px 32px;
  font-size: 20px;
  color: var(--grey-pink);
  background-color: transparent;
  border: 1px solid var(--grey-pink);
  border-radius: 10px;
  cursor: pointer;
}

.animatorNewCampaign__error {
  margin-bottom: 40px;
  min-height: 22px;
  color: $red;
}

:global(.selected-brand) {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
